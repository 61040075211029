<script setup>
import PaymentButton from "../buttons/PaymentButton.vue";
import { hasCustomerDetails } from "../../services/utils/invoice";
import { reportError } from "../../services/utils/telemetry";
import { showToast, toastStatus } from "../../services/utils/toast";
import { useInvoiceStore } from "../../stores/invoice";
import { updateInvoiceDetail } from "../../services/apis/invoice";
import { getWindcaveSession } from "../../services/apis/payment";
import { parseWindcaveSession } from "../../services/utils/payment";

const invoiceStore = useInvoiceStore();

const validateCustomerDetails = () => {
    if (!invoiceStore.invoice.customer.cms_number) {
        showToast(
            "Error: The customer associated with the invoice cannot be found by its CMS number.",
            toastStatus.ERROR
        );
        return false;
    }
    if (!hasCustomerDetails(invoiceStore.invoice.customer)) {
        showToast(
            "Error: Please complete all customer information.",
            toastStatus.ERROR
        );
        return false;
    }
    return true;
};

const onConfirm = async () => {
    if (!validateCustomerDetails()) {
        return;
    } else {
        invoiceStore.setLoading(true);

        try {
            // Update customer information
            await updateInvoiceDetail(
                invoiceStore.invoice.id,
                invoiceStore.invoice.customer
            );
        } catch (error) {
            reportError(error);
            showToast(
                "ERROR: An unexpected error has occurred, please contact us if the issue persists.",
                toastStatus.ERROR
            );
            invoiceStore.setLoading(false);
            return;
        }

        try {
            // // Get Windcave session
            // const session = parseWindcaveSession(
            //     await getWindcaveSession(invoiceStore.invoice.id)
            // );

            // // Cache session to local storage
            // localStorage.setItem(
            //     session.sessionId,
            //     JSON.stringify(invoiceStore.invoice)
            // );

            // // Redirect to Windcave HPP
            // window.location.href = session.paymentUrl;
            const companyName = encodeURI(
                invoiceStore.invoice.customer.company_name
            );
            const amount = invoiceStore.invoice.billing.total;
            const invoiceRef = invoiceStore.invoice.billing.total;
            const paymentUrl = `https://payment.simplypaid.com.au/reach?&cstnm=${companyName}&pymntamnt=${amount}&trt=${invoiceRef}&td=[type=ReachNZ]`;
            window.location.href = paymentUrl;
        } catch (error) {
            reportError(error);
            showToast(
                "ERROR: An unexpected error has occurred, please contact us if the issue persists.",
                toastStatus.ERROR
            );
        } finally {
            invoiceStore.setLoading(false);
        }
    }
};
</script>

<template>
    <div class="self-center text-center mt-8">
        <div class="text-start my-4">
            <p class="text-mute">
                By clicking the button below, you agree to the Terms &amp;
                Conditions.
            </p>
        </div>

        <div class="grid justify-center gap-4">
            <PaymentButton :on-confirm="onConfirm" />
        </div>
    </div>
</template>
